import EventBus from "@/backend/EventBus";
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import BaseResponse from "@/models/dtos/BaseResponse";
import Events from "@/backend/Events";

export default {
  async get(url: string, data: any = null): Promise<any> {
    // console.log('GET ❓', url);
    try {
      var axiosResponse = await axios.get(url, {
        params: data,
      });

      return this.handleAxiosResponse(axiosResponse);
    } catch (error: any) {
      if (error.response) {
        return this.handleAxiosError(error);
      } else {
        return this.handleConnectionError();
      }
    }
  },

  async post(
    url: string,
    data: any = null,
    options?: AxiosRequestConfig
  ): Promise<BaseResponse<any>> {
    // console.log('GET ❓', url);
    try {
      var axiosResponse = await axios.post(url, data, options);
      return this.handleAxiosResponse(axiosResponse);
    } catch (error: any) {
      if (error.response) {
        return this.handleAxiosError(error);
      } else {
        return this.handleConnectionError();
      }
    }
  },

  handleAxiosResponse(axiosResponse: AxiosResponse): any {
    if (!axiosResponse.data) {
      return null;
    }

    let data = axiosResponse.data;

    console.log("✅", axiosResponse);
    if (data.errorMessage) {
      EventBus.$emit(Events.DisplayError, data.errorMessage);
    }
    if (data.message) {
      EventBus.$emit(Events.DisplayMessage, data.message);
    }

    return axiosResponse.data;
  },

  handleAxiosError(axiosError: AxiosError): any {
    return axiosError.response?.data;
  },

  handleConnectionError(): any {
    return null;
  },
};
