import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Radar from "@/views/RadarView.vue";
import Login from "@/views/LoginView.vue";

import authorizationStore from "@/stores/authorization-store";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'radar',
    component: Radar,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if(to.meta?.requiresAuth){
    let isUserLoggedIn = authorizationStore.isUserLoggedIn();
    if(isUserLoggedIn){
      next();
      return;
    }
    else{
      next({name: 'login'});
      return;
    }
  }

  next();
});

export default router
