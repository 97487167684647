export default class Coordinates {
  latitude: number;
  longitude: number;
  accuracy: number;

  constructor(latitude: number, longitude: number, accuracy: number){
    this.latitude = latitude;
    this.longitude = longitude;
    this.accuracy = accuracy;
  }
}