
import Vue from "vue";
import EventBus from "./backend/EventBus";
import Events from "./backend/Events";

export default Vue.extend({
	name: "App",

	data: () => ({
		snack: {
			message: "",
			show: false,
			color: "primary",
		},
	}),

	mounted() {
		EventBus.$on(Events.DisplayError, (message: string) => {
			this.snack.message = message;
			this.snack.color = "error";
			this.snack.show = true;
		});
		EventBus.$on(Events.DisplayMessage, (message: string) => {
			this.snack.message = message;
			this.snack.color = "success";
			this.snack.show = true;
		});
	},
});
