import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import authorizationStore from "@/stores/authorization-store"
import axios from "axios";

axios.interceptors.request.use(
  config => {
    let token = authorizationStore.getToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  error => {
    return Promise.reject(error);
  }
);
// var apiUrl = "https://localhost:7041/api/";
var apiUrl = "https://game-api.keplerout.eu/api/";
axios.defaults.baseURL = apiUrl;

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
