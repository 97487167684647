
  import { Component, Vue } from 'vue-property-decorator';
  import authorizationApi from "@/api/authorizationApi";
  import authorizationStore from "@/stores/authorization-store";

  @Component({
    name: "LoginView"
  })
  export default class LoginView extends Vue {

    authorizationRequest: any = {pin: ""};

    pin: string = "";

    async login(){
      var response = await authorizationApi.authorizeWithPin(this.authorizationRequest);
      console.log("🔵 -> file: LoginView.vue:51 -> LoginView -> login -> response:", response);
      if(response.success){
        authorizationStore.setToken(response.data.token);
        await authorizationApi.test();
        this.$router.push("/");
      }
      else{
        //  TODO: notify user about wrong PIN
      }

    }

    mounted(){
      authorizationStore.clearToken();
    }
    
  }
