import Coordinates from "@/models/shared/Coordinates";

export function calculateDistance(from: Coordinates, to: Coordinates): number {
  let startingLat = degreesToRadians(from.latitude);
  let startingLong = degreesToRadians(from.longitude);
  let destinationLat = degreesToRadians(to.latitude);
  let destinationLong = degreesToRadians(to.longitude);

  // Radius of the Earth in meters
  let radius = 6378137;

  // Haversine equation
  let distanceInMeters =
    Math.acos(
      Math.sin(startingLat) * Math.sin(destinationLat) +
      Math.cos(startingLat) *
      Math.cos(destinationLat) *
      Math.cos(startingLong - destinationLong)
    ) * radius;

  return distanceInMeters;
}

export function calculateBearing(from: Coordinates, to: Coordinates): number {
  let startingLat = degreesToRadians(from.latitude);
  let startingLong = degreesToRadians(from.longitude);
  let destinationLat = degreesToRadians(to.latitude);
  let destinationLong = degreesToRadians(to.longitude);

  let y =
    Math.sin(destinationLong - startingLong) *
    Math.cos(destinationLat);

  let x =
    Math.cos(startingLat) *
    Math.sin(destinationLat) -
    Math.sin(startingLat) *
    Math.cos(destinationLat) *
    Math.cos(destinationLong - startingLong);

  let bearing = Math.atan2(y, x);
  bearing = radiansToDegrees(bearing);
  return (bearing + 360) % 360;
}

export function getBearingDirection(bearing: number): string {
  const directions = ['Sever', 'Severo-Východ', 'Východ', 'Jiho-Východ', 'Jih', 'Jiho-Západ', 'Západ', 'Severo-Západ'];
  const offset = 22.5;
  let adjustedBearing = (bearing + offset) % 360
  let index = Math.floor(adjustedBearing / 45);
  return directions[index];
}

export function degreesToRadians(degrees: number) {
  var radians = (degrees * Math.PI) / 180;
  return radians;
}

export function radiansToDegrees(radians: number) {
  return (radians * 180) / Math.PI;
}