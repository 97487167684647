const TOKEN_KEY = "token";

const store = {
  setToken(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
  },

  getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  },

  clearToken(): void {
    localStorage.removeItem(TOKEN_KEY);
  },

  isUserLoggedIn(): boolean {
    try {
      var token = localStorage.getItem(TOKEN_KEY);
      if (token == null) {
        return false;
      }
      return Date.now() < JSON.parse(atob(token.split(".")[1])).exp * 1000;
    } catch (err) {
      console.error(
        "❗ -> file: authorization-store.ts:22 -> isUserLoggedIn -> err:"
      );
      return false;
    }
  },
};

export default store;
