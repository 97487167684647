
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import TargetDto from "@/models/dtos/TargetDto";
import GeolocationStatus from "@/models/shared/GeolocationStatus";
import Compass from "@/components/geolocation/compass.vue";

import {
  calculateDistance,
  calculateBearing,
  getBearingDirection,
} from "@/utils/geolocation-utils";
import Coordinates from "@/models/shared/Coordinates";
import gameApi from "@/api/gameApi";

@Component({
  name: "TargetCard",
  components: {
    Compass,
  },
})
export default class TargetCard extends Vue {
  target!: TargetDto;
  proximityTolerance: number = 5;
  loading: boolean = true;

  @Prop({ required: true })
  currentGeolocationStatus!: GeolocationStatus;

  @Watch("currentGeolocationStatus")
  onGeolocationStatusChanged(status: GeolocationStatus) {
    if (status != null) {
      let isInProximity = this.isInProximity();
    }
  }

  mounted() {
    this.loadTarget();
  }

  async loadTarget() {
    this.loading = true;
    var targetResponse = await gameApi.getActiveTarget();

    if (targetResponse.success) {
      this.target = targetResponse.data;
      this.loading = false;
    }
  }

  async finishTarget() {
    this.loading = true;
    var response = await gameApi.finishTarget(this.target);
    if (response.success) {
      await this.loadTarget();
    } else {
      this.target.password = "";
    }
    this.loading = false;
  }

  calcualteDistance(): number {
    if (!this.currentGeolocationStatus.coordinates) {
      return Infinity;
    }

    if (!this.target) {
      return Infinity;
    }

    return calculateDistance(
      this.currentGeolocationStatus.coordinates,
      new Coordinates(this.target.latitude, this.target.longitude, 0)
    );
  }

  getDistanceText() {
    if (!this.currentGeolocationStatus.coordinates) {
      return "---";
    }
    const distanceInMeters = calculateDistance(
      this.currentGeolocationStatus.coordinates,
      new Coordinates(this.target.latitude, this.target.longitude, 0)
    );
    return distanceInMeters > 999
      ? `~${(distanceInMeters / 1000).toFixed(2)}km`
      : `~${Math.round(distanceInMeters)}m`;
  }

  calculateBearing(): number {
    if (!this.currentGeolocationStatus.coordinates) {
      return 0;
    }
    const bearingInDegrees = calculateBearing(
      this.currentGeolocationStatus.coordinates,
      new Coordinates(this.target.latitude, this.target.longitude, 0)
    );
    return bearingInDegrees;
  }

  getDirection() {
    if (!this.currentGeolocationStatus.coordinates) {
      return "---";
    }

    var bearing = this.calculateBearing();
    return getBearingDirection(bearing);
  }

  getBearingText(): string {
    if (!this.currentGeolocationStatus.coordinates) {
      return "---";
    }
    return `${calculateBearing(
      this.currentGeolocationStatus.coordinates,
      new Coordinates(this.target.latitude, this.target.longitude, 0)
    ).toFixed(2)}°`;
  }

  getToleranceText(): string {
    if (!this.currentGeolocationStatus.coordinates) {
      return "---";
    }
    try {
      return `${(
        (this.currentGeolocationStatus.getAccuracy() as number) +
        this.proximityTolerance
      ).toFixed(2)}m`;
    } catch {
      return "---";
    }
  }

  isInProximity(): boolean {
    if (!this.currentGeolocationStatus.coordinates) {
      return false;
    }

    let distance = this.calcualteDistance();
    return (
      distance <
      this.proximityTolerance +
        this.currentGeolocationStatus.coordinates.accuracy
    );
  }
}
