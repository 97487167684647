import api from "./api";

export default {

  url: "authorization/",

  async authorizeWithPin(request: string){
    return api.post(this.url + "authorizeWithPin", request);
  },

  async test(){
    return api.get(this.url);
  }

}