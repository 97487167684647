
import { Component, Vue } from "vue-property-decorator";

import Coordinates from "@/models/shared/Coordinates";
import GeolocationStatus from "@/models/shared/GeolocationStatus";
import CoordinatesDto from "@/models/dtos/CoordinatesDto";

import GeolocationStatusBar from "@/components/geolocation/geolocation-status-bar.vue";
import TargetCard from "@/components/geolocation/target-card.vue";

import gameApi from "@/api/gameApi";

@Component({
  name: "RadarView",
  components: {
    GeolocationStatusBar,
    TargetCard,
  },
})
export default class RadarView extends Vue {
  currentGeolocationStatus: GeolocationStatus = new GeolocationStatus(null);
  updateInterval: number = 0;

  options: any = {
    enableHighAccuracy: true,
  };

  geolocationId: number = 0;

  updateStatus(coordinates: Coordinates) {
    this.currentGeolocationStatus = new GeolocationStatus(coordinates);
  }

  success = (position: any) => {
    let coords = position.coords;
    console.log("🔵 -> file: RadarView.vue:56 -> RadarView -> coords:", coords);

    let lat = coords.latitude;
    let lng = coords.longitude;
    let accuracy = coords.accuracy;

    let coordinates = new Coordinates(lat, lng, accuracy);
    this.updateStatus(coordinates);
  };

  error = (err: any) => {
    console.warn(err);
  };

  mounted() {
    this.initTracking();
    this.updateInterval = setInterval(() => this.updateCoordinates(), 5000);
  }

  async updateCoordinates() {
    if (
      this.currentGeolocationStatus &&
      this.currentGeolocationStatus.connected &&
      this.currentGeolocationStatus.coordinates
    ) {
      var response = await gameApi.updateCoordinates(
        new CoordinatesDto(
          this.currentGeolocationStatus.coordinates.latitude,
          this.currentGeolocationStatus.coordinates.longitude
        )
      );
    }
  }

  beforeDestroy() {
    this.stopTracking();
    clearInterval(this.updateInterval);
  }

  //  Geolocation
  initTracking() {
    if ("geolocation" in navigator) {
      this.geolocationId = navigator.geolocation.watchPosition(
        this.success,
        this.error,
        this.options
      );
    } else {
      console.error("NO GEOLOCATION");
    }
  }

  stopTracking() {
    console.log(
      "🔵 -> file: RadarView.vue:178 -> RadarView -> stopTracking -> stopTracking:",
      "GPS TRACKING STOPPED"
    );
    navigator.geolocation.clearWatch(this.geolocationId);
  }
  //  END Geolocation
}
