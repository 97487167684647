import { render, staticRenderFns } from "./target-card.vue?vue&type=template&id=f91d0098&scoped=true&"
import script from "./target-card.vue?vue&type=script&lang=ts&"
export * from "./target-card.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f91d0098",
  null
  
)

export default component.exports