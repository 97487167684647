import Coordinates from "./Coordinates";

export default class GeolocationStatus {
  coordinates: Coordinates | null;
  connected: boolean;
  timestamp: Date;

  constructor(coordinates: Coordinates | null){
    this.coordinates = coordinates;
    this.connected = coordinates != null;
    this.timestamp = new Date();
  }

  getLatitude():number | string{
    return this.coordinates == null ? '---' : this.coordinates.latitude;
  }

  getLongitude():number | string{
    return this.coordinates == null ? '---' : this.coordinates.longitude;
  }

  getAccuracy():number | string{
    return this.coordinates == null ? '---' : this.coordinates.accuracy;
  }
}