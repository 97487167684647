import { render, staticRenderFns } from "./RadarView.vue?vue&type=template&id=2457a49a&scoped=true&"
import script from "./RadarView.vue?vue&type=script&lang=ts&"
export * from "./RadarView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2457a49a",
  null
  
)

export default component.exports