import api from "@/api/api";
import BaseResponse from "@/models/dtos/BaseResponse";
import TargetDto from "@/models/dtos/TargetDto";
import CoordinatesDto from "@/models/dtos/CoordinatesDto";

export default {
  url: "game/",

  getActiveTarget(): Promise<BaseResponse<TargetDto>>{
    return api.get(this.url + 'getActiveTarget');
  },

  finishTarget(target: TargetDto): Promise<BaseResponse<TargetDto>>{
    return api.post(this.url + 'finishTarget', target);
  },

  updateCoordinates(coordinates: CoordinatesDto): Promise<BaseResponse<CoordinatesDto>>{
    return api.post(this.url + 'updateCoordinates', coordinates);
  },
}