
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { useDeviceOrientation } from "@vueuse/core";
import TargetDto from "@/models/dtos/TargetDto";
import { degreesToRadians } from "@/utils/geolocation-utils";

@Component({
  name: "Compass",
})
export default class Compass extends Vue {
  @Prop({ required: true })
  distance!: number;

  @Prop({ required: true })
  bearing!: number;

  isIOS =
    navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    navigator.userAgent.match(/AppleWebKit/);

  checkForIos() {
    try {
      if (typeof (DeviceMotionEvent as any).requestPermission === "function") {
        console.warn("OK");
      } else {
        console.warn("no DeviceMotionEvent");
      }
    } catch (err) {
      alert(err);
    }
  }

  mounted() {
    // this.checkForIos();
    if (this.isIOS) {
      (DeviceMotionEvent as any).requestPermission().then((response: any) => {
        if (response == "granted") {
          window.addEventListener(
            "deviceorientationabsolute",
            this.handler,
            true
          );
        } else {
          alert("has to be allowed!");
        }
      });
    } else {
      window.addEventListener("deviceorientationabsolute", this.handler, true);
    }
  }

  async setMotionListeners() {
    if (typeof (DeviceMotionEvent as any).requestPermission === "function") {
      await (DeviceMotionEvent as any)
        .requestPermission()
        .catch((error: any) => {
          console.log("Error getting sensor permission: %O", error);
          return; // Exit out of logic
        });
    }
    window.addEventListener("deviceorientationabsolute", this.handler, true);
  }

  orientation: any = 0;

  handler = (e: any) => {
    console.log("🔵 -> file: compass.vue:46 -> Compass -> e:", e);
    this.setOrientation(e.webkitCompassHeading || Math.abs(e.alpha - 360));
    this.setPointPosition();
  };

  setOrientation(orientation: any) {
    this.orientation = orientation;
  }

  point: HTMLElement | null = null;
  radar: HTMLElement | null = null;
  offset: number = 0;

  setPointPosition() {
    try {
      this.point = document.getElementById("point");
      this.radar = document.getElementById("radar");

      this.offset = this.radar!.offsetWidth / 2;

      var clampedDistance =
        this.distance > this.offset ? this.offset : this.distance;
      var adjustedBearing = this.bearing - this.orientation;

      var x = clampedDistance * Math.sin(degreesToRadians(adjustedBearing));
      var y = -clampedDistance * Math.cos(degreesToRadians(adjustedBearing));

      this.point!.style.left = x + this.offset + "px";
      this.point!.style.top = y + this.offset + "px";
    } catch (err) {
      console.error(
        "🔵 -> file: compass.vue:72 -> Compass -> setPointPosition -> err:",
        err
      );
    }
  }
}
