
  import { Component, Prop, Vue } from 'vue-property-decorator';
import GeolocationStatus from "@/models/shared/GeolocationStatus";

  @Component({
    name: "GeolocationStatusBar"
  })
  export default class GeolocationStatusBar extends Vue {

    @Prop({required: true})
    status!: GeolocationStatus;
    
  }
